<template>
  <div
    v-if="socials.length"
    class="ui-socials"
    :class="variant"
  >
    <p v-if="title">
      {{ title }}
    </p>

    <ul class="ui-socials__list">
      <li
        v-for="(social, index) in socials"
        :key="index"
        class="ui-socials__item"
      >
        <T3Link
          :to="social"
          class="ui-socials__item-link"
        >
          <UiImg
            :image="social.icon"
            class="ui-socials__item-icon"
          />
        </T3Link>
      </li>
    </ul>
  </div>
</template>

<script>
import UiImg from '~ui/components/Ui/UiImg/UiImg.vue'

export default {
  components: { UiImg },
  props: {
    socials: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.ui-socials {
  display: flex;
  position: relative;
  z-index: z-index(base);

  & p {
    margin: 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: rem(12px) rem(24px);
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-query(md) {
      justify-content: flex-end;
      width: auto;
    }
  }

  &__item {
    &-icon {
      display: block;
      width: rem(20px);
      height: rem(20px);
      object-fit: contain;
      filter: brightness(0) invert(1);
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      outline-offset: 4px;
    }

    &-link:is(:hover, :active, :focus) &-icon {
      filter: color(primary, $colors-filter);
    }
  }

  &--vertical &__list {
    flex-flow: column;
  }

  &--vertical &__item {
    margin-block: spacing(sm);

    @include media-query(md) {
      margin-inline: spacing(md);
    }
  }
}
</style>
